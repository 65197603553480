@import url('https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css');

i {
    font-size: 3rem;
    color: #dcff79;
}



* {
    box-sizing: border-box;
    font-family: 'Chivo', sans-serif;
}

/* PERSONAL PAGE */

.personal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #004aad;
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.title-div {
    display: flex;
    flex-direction: column;
}

.profilepic-div {
    position: relative;
    bottom: 0;
    width: 100vw;
    height: 50%;
    margin: 0;
}

.profile-pic {
    width: 100%;
    max-height: 900px;
    position: absolute;
    bottom: 0px;
    left: 0;
    object-fit: contain;
    z-index: 2;
}

.profile-pic-background {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: rgb(18, 18, 18);
}

h1 {
    color: #dcff79;
    font-weight: 900;
    font-size: 9vw;
    text-shadow: 2px 2px 2px black;
    line-height: 8vw;
    width: 50%;
    margin-top: 6vw;
    margin-left: 6vw;
}

h4 {
    color: #dcff79;
    font-weight: 900;
    font-size: 4vw;
    text-shadow: 2px 2px 2px black;
    line-height: 4vw;
    width: 50%;
    margin-top: 6vw;
    margin-left: 6vw;
}

.profilepic-div button {
    display: none;
}



.projects-btn {
    color: #cfb9ff;
    font-weight: bold;
    font-size: 4vw;
    background-color: black;
    border: 2px solid black;
    padding: 8px;
    margin-bottom: 1vh;
    margin-left: 6vw;
    cursor: pointer;
}

.contacts {
    position: absolute;
    bottom: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 3;
}

.getintouch {
    height: 15vw;
    cursor: pointer;
}

.arrow {
    width: 30%;
    margin-left: 10px;
    margin-top: 5px;
}

svg {
    fill: #dcff79;
    margin-left: 5px;
    margin-top: 15px;
}

a {
    text-decoration: none;
}

/* PROJECTS PAGE */

.projects {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(13, 1fr);
    background-color: black;
    width: 100%;
    min-height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    grid-template-areas: 
    'science backtome1'
    'science .'
    'science puppy'
    '. puppy'
    'form puppy'
    'form .'
    'form photo'
    '. photo'
    'dashboard photo'
    'dashboard .'
    'dashboard directory'
    '. directory'
    'backtome2 directory';
}

.projects.main {
    display: flex;
    flex-direction: column;
}

.project-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
}

.project-div.main {
    flex-direction: column;
    width: 70%;
    margin: auto;
}

.project-main-icons-div {
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: space-between;
    width: 90%;
    align-items: center;
}

.project-main-icons-div svg {
    margin: 5px;
}

.project-main-icons-div div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.project-div.main p {
    margin: 30px;
    font-size: 1.5rem;
}

.project-main-icons-div a {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#repo-p {
    font-size: .9rem;
    margin: 5px;
}

.project-info.div {
    display: flex;
    flex-direction: row;
}

.school-projects-btn {
    color: #cfb9ff;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: black;
    border: 2px solid #cfb9ff;
    width: 100%;
    padding: 16px;
    cursor: pointer;
}

.school-projects-link {
    margin: 50px auto;
    width: 80%;
}

.project-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h3 {
    color: #cfb9ff;
    font-size: 1.2rem;
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
}
.main h3 {
    font-size: 1.8rem;
}

p {
    font-size: 0.8rem;
    color: #dcff79;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.project-githubLink {
    margin-bottom: 15px;
}

.project-icon {
    width: 80%;
}

.zerodue-icon {
    width: 30%;
    margin-bottom: 30px;
}

.skills {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
}

.skills i {
    font-size: 2.3rem;
    margin: 3px;
    color: #cfb9ff;
}

.back-to-me {
    height: 50px;
    margin: 20px;
    cursor: pointer;
}

.github-svg {
    width: 30px;
}

/* GRID AREAS */

#fullstack-div {
    grid-area: science;
}

#puppy-div {
    grid-area: puppy;
}

#form-div {
    grid-area: form;
}

#photo-div {
    grid-area: photo;
}

#dashboard-div {
    grid-area: dashboard;
}

#directory-div {
    grid-area: directory;
}

#back-to-me-1 {
    grid-area: backtome1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

#back-to-me-2 {
    grid-area: backtome2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}



/* MEDIA QUERIES */



@media screen and (orientation: landscape) {

    .profile-pic-background {
        display: none;
    }
}


@media only screen and (min-width: 600px) {


    @media screen and (orientation:portrait) { 

        h1 {
            font-size: 6vw;
            line-height: 5vw;
            margin-top: 8vw;
            margin-left: 8vw;
            width: 60%;
        }

        
        .projects-btn {
            font-size: 3vw;
            width: 50%;
            padding: 9px;
            margin-left: 8vw;
        }

        #about-btn {
            width: 28%;
            margin-bottom: 1.5vh;
        }

        
        .arrow {
            width: 30%;
            margin-left: 10px;
            margin-bottom: 20px;
        }

        svg {
            width: 80px;
            height: 80px;
            margin-left: 15px;
        }

        .project-icon {
            width: 60%;
        }

        #github-svg {
            margin: 0;
        }

        .project-main-icons-div {
            width: 50%;
        }
       
        .school-projects-link {
            width: 50%;
        }
    }


    @media screen and (orientation:landscape) { 

        /* PERSONAL */

        .personal {
            flex-direction: row;
        }

        h1 {
           font-size: 10vh;
           line-height: 8vh;
           margin-top: 15vh;
           margin-left: 15vh;
           width: 60%;
        }

        .projects-btn {
            font-size: 4vh;
            width: 65%;
            padding: 9px;
            margin-left: 10vh;
        }

        #about-btn {
            width: 40%;
            margin-bottom: 1.5vh;
        }

        .profilepic-div {
            height: 80%;
            width: 50%;
            margin-top: auto;
            margin-bottom: auto;
        }

        .profile-pic {
            width: 100%;
        }

        .getintouch {
            height: 15vh;
        }
        
        .arrow {
            width: 15vh;
            margin-left: 5vh;
            margin-bottom: 3vh;
        }

        svg {
            width: 8vh;
            margin-left: 2vh;
        }

        /* PROJECTS */

        .projects {
            grid-template-columns: repeat(6, calc(100% / 6));
            grid-template-rows: 50% 50%;
            background-color: black;
            width: 100%;
            margin: auto;
            padding: 20px;
            position: absolute;
            top: 0;
            left: 0;
            grid-template-areas: 
            'science . directory . photo backtome1'
            'backtome2 puppy . form . dashboard';
        }

        .back-to-me {
            height: 40px;
            margin-top: 5vh;
        }

        #back-to-me-1 {
            grid-area: backtome1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            height: 50px;
            margin: 20px;
        }

        #back-to-me-2 {
            display: none;
        }

        .project-main-icons-div {
            width: 50%;
        }
       
        .school-projects-link {
            width: 50%;
        }
    }
}


@media only screen and (min-width: 1024px) {
    
    .personal {
        align-items: center;
    }

    h1 {
        font-size: 7vh;
        line-height: 6vh;
        margin-top: 7vh;
        margin-left: 7vh; 
    }

    h4 {
        font-size: 4vh;
        line-height: 4vh;
        margin-left: 10vw;
    }

    .projects-btn {
        font-size: 3vh;
        width: 65%;
        padding: 9px;
        margin-left: 7vh;
    }

    .profilepic-div {
        width: 70%;
        margin-top: 2vh;
    }

    .getintouch {
        height: 10vh;
    }

    .arrow {
        width: 11vh;
        margin-left: 2vh;
        margin-bottom: 1vh;
    }

    svg {
        height: 6vh;
        margin-left: 1vh;
    }

    .projects {
        grid-template-columns: repeat(6, calc(100% / 6));
        grid-template-rows: 50% 50%;
        background-color: black;
        width: 100%;
        margin: auto;
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        grid-template-areas: 
        'science . directory . photo backtome1'
        'backtome2 puppy . form . dashboard';
    }

    .project-main-icons-div {
        width: 40%;
    }
   
    .school-projects-link {
        width: 23%;
    }
}

@media only screen and (min-width: 1200px) {
    .profilepic-div {
        width: 40vw;
    }

    .title-div {
        width: 70%;
    }

    h1 {
        width: 40%;
        font-size: 4vw;
        line-height: 4vw;
        margin-top: 3vw;
        margin-left: 10vw;
    }

    h4 {
        font-size: 4vh;
        line-height: 4vh;
        margin-left: 10vw;
    }

    .projects-btn {
        font-size: 1.8vw;
        width: 45%;
        padding: 14px;
        margin-left: 10vw;
    }

    #about-btn {
        width: 25%;
        margin-bottom: 1.5vh;
    }

    .contacts {
        position: absolute;
        bottom: -14%;
        width: 78%;
        left: 18%;
    }


    .back-to-me {
        height: 70px;
    }

    h3 {
        font-size: 1.5rem;
    }
    
    p {
        font-size: 1.2rem;
    }

    .skills img {
        width: 40px;
    }
}
    